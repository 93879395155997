.split-width-carousel-formatter{
  &--peeking {
    .split-width-carousel {
      .slick-list {
        padding-#{$rdirection}: 8%;
        @include breakpoint($landscape-up) {
          padding-#{$rdirection}: 0;
        }
      }
      .slick-slide {
        &.slick-current+,
        &.slick-active+ {
          .slick-slide {
            .content-block {
              opacity: 1;
              transition: opacity $transition-effect $transition-duration;
              transform: scale(0.755);
              transform-origin: center $ldirection;
              margin-#{$ldirection}: 3vw;
              @include breakpoint($landscape-up) {
                transform: scale(1);
                margin-#{$ldirection}: 0;
              }
            }
          }
        }
        &:not(.slick-current),
        &:not(.slick-active) {
          .content-block-split-width {
            > .column__1,
            > .column__2{
              &:not(.split-width-carousel__media-container) {
                opacity: 0;
                height: 0;
                transition: opacity $transition-effect $transition-duration;
              }
              &.split-width-carousel__media-container {
                opacity: 1;
                transition: opacity $transition-effect $transition-duration;
              }
            }
          }
        }
      }
    }
    &-desktop {
      .split-width-carousel {
        .slick-list {
          @include breakpoint($landscape-up) {
            padding-#{$rdirection}: 10%;
          }
        }
        .slick-slide {
          &.slick-active+ {
            .slick-slide {
              .content-block {
                @include breakpoint($landscape-up) {
                  transform: scale(0.8);
                  transform-origin: center $ldirection;
                  margin-#{$ldirection}: 3vw;
                }
              }
            }
          }
          @include breakpoint($landscape-up) {
            opacity: 1;
          }
        }
      }
    }
  }
}
