.split-width-carousel-formatter {
  position: relative;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  &.content-container {
    max-width: $content-block-max-width;
  }
  &__wrapper {
    .split-width-carousel {
      .split-width-carousel__slide {
        visibility: hidden;
        &:first-child {
          visibility: visible;
        }
      }
      &.slick-initialized {
        .split-width-carousel__slide {
          visibility: visible;
        }
      }
      .slick-slide {
        margin: 0;
        transform: scale(1);
        transform-origin: center $ldirection;
        @include breakpoint($landscape-up) {
          opacity: 0;
          transition: opacity 0.3s;
        }
      }
      .slick-active {
        @include breakpoint($landscape-up) {
          opacity: 1;
        }
      }
      &.slick-slider {
        padding: 0;
      }
      .slick-list {
        padding: 0;
      }
      &__controls {
        position: relative;
        width: 100px;
        display: inline-block;
        height: 30px;
        margin: 2vh 15px;
        @include breakpoint($landscape-up) {
          margin-#{$ldirection}: 0;
          padding: 12px 36px 0;
          width: 140px;
        }
        &.pc-hidden {
          display: inline-block;
          @include breakpoint($landscape-up) {
            display: none;
          }
        }
        &.mobile-hidden {
          display: none;
          @include breakpoint($landscape-up) {
            display: inline-block;
          }
        }
      }
      &__dots {
        padding: 12px 36px 0;
        &.pc-hidden {
          display: inline-block;
          @include breakpoint($landscape-up) {
            display: none;
          }
        }
        &.mobile-hidden {
          display: none;
          @include breakpoint($landscape-up) {
            display: inline-block;
            padding: 12px 36px 0;
          }
        }
      }
    }
    .slick-arrow {
      position: relative;
    }
    .slick-next,
    .slick-prev{
      opacity: 1;
      margin: 0;
      stroke: $color-silver;
      stroke-width: 10;
      width: 20px;
      transition: stroke $transition-duration $transition-effect;
      &:hover {
        stroke: $color-light-black;
        transition: stroke $transition-duration $transition-effect;
      }
    }
    .slick-prev {
      #{$ldirection}: 0;
    }
    .slick-next {
      #{$rdirection}: 0;
    }
    .slick-dots {
      position: absolute;
      bottom: 0;
      text-align: $ldirection;
      margin-#{$ldirection}: 15px;
      @include breakpoint($landscape-up) {
        margin-#{$ldirection}: 0;
      }
      li {
        margin: 0;
        width: 33px;
        button {
          width: 20px;
        }
      }
      li button::before {
        height: 2px;
        color: $color-black;
        background: $color-black;
      }
    }
    .content-block--split-width {
      padding: 0 8%;
    }
  }
  &--no-loop {
    .slick-slide {
      &:first-of-type {
        .slick-prev-button {
          opacity: 0.4;
          pointer-events: none;
        }
      }
      &:last-of-type {
        .slick-next-button {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }
}
